export class Time
{
	constructor(start)
	{
		this.serverTime = new Date(start);
		this.clientStartTime = new Date();
	}

	now()
	{
		const clientCurrentTime = new Date();
		const timeElapsed = clientCurrentTime - this.clientStartTime;
		const currentServerTime = new Date(this.serverTime.getTime() + timeElapsed);
		return currentServerTime;
	}

	daysUntil(date, options)
	{
		const days = Math.ceil((new Date(date) - this.now()) / (24 * 60 * 60 * 1000))
		if(options?.timeToStart)
		{
			if(days < -1)
			{
				return `Started ${-days} days ago`;
			} else if(days === -1)
			{
				return 'Started yesterday!';
			} else if(days === 0)
			{
				return 'Starts right now!';
			} else if(days === 1)
			{
				return 'Starts tomorrow';
			} else
			{
				return `Starts in ${days} days`;
			}
		} else
		{
			return Math.max(days, 0);
		}
	}

	timeUntilGuildEnd(guild)
	{
		const endTime = new Date(guild.end_time);
		const timeUntilEnd = endTime - this.now();
		const days = Math.floor(timeUntilEnd / (24*60*60*1000));
		const hours = Math.floor((timeUntilEnd % (24*60*60*1000)) / (60*60*1000));
		const minutes = Math.floor((timeUntilEnd % (60*60*1000)) / (60 * 1000));
		const seconds = Math.floor((timeUntilEnd % (60*1000)) / 1000);

		return {days, hours, minutes, seconds};
	}

	formatDate(timestamp)
	{
		const currentTime = this.now();
		const timeDifference = currentTime - new Date(timestamp).getTime();

		if (timeDifference < MINUTE)
		{
			return 'less than a minute ago';
		} else if (timeDifference < HOUR)
		{
			const minutes = Math.floor(timeDifference / MINUTE);
			return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
		} else if (timeDifference < DAY)
		{
			const hours = Math.floor(timeDifference / HOUR);
			return `${hours} hour${hours > 1 ? 's' : ''} ago`;
		} else if (timeDifference < WEEK)
		{
			const days = Math.floor(timeDifference / DAY);
			return `${days} day${days > 1 ? 's' : ''} ago`;
		} else {
			return new Date(timestamp)
				.toLocaleDateString(undefined, {day:'numeric', month:'short'});
		}
	}
}
const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;

export const getUtcOffset = () =>
{
	const date = new Date();
	const sign = (date.getTimezoneOffset() > 0) ? "-" : "+";
	const offset = Math.abs(date.getTimezoneOffset());
	const hours = String(Math.floor(offset / 60)).padStart(2,'0');
	const minutes = String(offset % 60).padStart(2,'0');
	return 'UTC' + sign + hours + ":" + minutes;
}

import React, { useState, useRef, useEffect } from 'react';

const CurrencyInput = ({ onChange, defaultValue }, ref) =>
{
	const [value, setValue] = useState(defaultValue || '0.00');
	const prev = useRef('');

	useEffect(() =>
	{
		if(value===prev.current) return;

		onChange(value);
		prev.current = value;
	}, [value, onChange]);

	return (
		<input
			type="text"
			value={value}
			onChange={(e) =>
			{
				let cursor = e.target.selectionStart;
				let input = e.target.value.replace(/[^0-9.]/g, '');

				if(input.length>value.length && input.slice(cursor,cursor+1)!=='.')
				{
					input = input.slice(0,cursor) + input.slice(cursor+1)
				}

				let parsedInput = parseFloat(input);
				if (isNaN(parsedInput))
				{
					setValue('0.00');
				} else if(input.includes('.'))
				{
					setValue(parsedInput.toFixed(2));
				}

				setTimeout(() => {
					e.target.selectionStart = cursor;
					e.target.selectionEnd = cursor;
				}, 0);
			}}
		/>
	);
}

export default CurrencyInput;
